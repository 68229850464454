<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row>
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>

              <!-- Code Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.transactionsCashAdvanceRequestApproval.singular.code')"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    v-model="form.code"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <!-- Date Field -->
              <b-col cols="12" md="2">
                <b-form-group
                  :label="$t('apps.transactionsCashAdvanceRequestApproval.singular.date')"
                  label-for="date"
                >
                  <b-form-input
                    id="date"
                    v-model="form.date"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <!-- Purpose Date Field -->
              <b-col cols="12" md="2">
                <b-form-group
                  :label="$t('apps.transactionsCashAdvanceRequestApproval.singular.purposeDate')"
                  label-for="purpose-date"
                >
                  <b-form-input
                    id="purpose-date"
                    v-model="form.purposeDate"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <!-- Employee Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.transactionsCashAdvanceRequestApproval.singular.employee')"
                  label-for="employee-id"
                >
                  <b-form-input
                    id="employee-id"
                    v-model="form.employeeId"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

              <!-- Account Field -->
              <b-col cols="12" md="2">
                <b-form-group
                  :label="$t('apps.transactionsCashAdvanceRequestApproval.singular.type')"
                  label-for="type-code"
                >
                  <b-form-input
                    id="type-code"
                    v-model="form.type"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Description Field -->
            <b-form-group
              :label="$t('apps.transactionsCashAdvanceRequestApproval.singular.notes')"
              label-for="notes"
            >
              <b-form-textarea
                id="notes"
                v-model="form.description"
                :disabled="true"
              />
            </b-form-group>

            <b-table
              id="refFormTable"
              ref="refFormTable"
              fixed
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="form.details"
              primary-key="id"
              show-empty
              class="mt-2 position-relative"
            >
              <!-- Custom Header Column -->
              <template #head(actions)>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="mx-auto"
                />
              </template>

              <!-- Styling width -->
              <!-- Column: Account -->
              <template #cell(account)="{ index }">
                <b-form-group>
                  <b-form-input
                    id="account"
                    v-model="form.details[index].account"
                    :disabled="true"
                  />
                </b-form-group>
              </template>

              <!-- Column: Description -->
              <template #cell(description)="{ index }">
                <b-form-group>
                  <b-form-input
                    v-model="form.details[index].description"
                    :disabled="true"
                  />
                </b-form-group>
              </template>

              <!-- Column: Request Amount -->
              <template #cell(requestAmount)="{ index }">
                <b-form-group>
                  <cleave
                    :key="index"
                    v-model.number="form.details[index].requestAmount"
                    class="text-right form-control"
                    :options="numeric"
                    :disabled="true"
                  />
                </b-form-group>
              </template>

              <!-- Column: Approved Amount -->
              <template #cell(approvedAmount)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="approved_amount"
                    :vid="`details-${index}`"
                    :rules="`${!actions.isPreview ? 'required|between:1,' + Number(form.details[index].requestAmount) : ''}`"
                  >
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].approvedAmount"
                      :state="errors.length > 0 ? false : null"
                      class="text-right form-control"
                      :options="numeric"
                      @blur="sumApprovedAmount"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>

              <!-- Costum Footer -->
              <template v-slot:custom-foot="{}">
                <b-tr>
                  <b-td class="text-right" colspan="2">
                    <div class="mb-0 h4 font-weight-bolder">Total</div>
                  </b-td>
                  <b-td class="text-right">
                    <div class="mb-0 h4 font-weight-bolder">{{ form.requestAmount }}</div>
                  </b-td>
                  <b-td class="text-right">
                    <div class="mb-0 h4 font-weight-bolder">
                      <cleave
                        v-model.number="form.approvedAmount"
                        class="text-right form-control d-none"
                        :options="numeric"
                      />
                      {{ totalApprovedAmount }}
                    </div>
                  </b-td>
                </b-tr>
              </template>
            </b-table>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-cash-advance-request-approval-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.approve') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToApprove') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import {ref} from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, between} from '@validations'
import {formatCurrency, formatDatepicker} from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Cleave
  },
  data: () => ({
    tableColumns: [
      {key: 'account', width: 'auto', label: 'Account', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0'},
      {key: 'description', width: 'auto', label: 'Description', thClass: 'bg-transparent', tdClass: 'align-top'},
      {key: 'requestAmount', width: '24%', label: 'Request Amount', thClass: 'bg-transparent width-25-per', tdClass: 'align-top text-right'},
      {key: 'approvedAmount', width: '24%', label: 'Approved Amount', thClass: 'bg-transparent width-25-per', tdClass: 'align-top text-right'}
    ],
    required,
    between
  }),
  setup () {
    const {
      paramsId,
      formRef,
      actions,
      changeToEdit,
      get,
      customStore
    } = useFormResource({
      localeContextPath: 'apps.transactionsCashAdvanceRequestApproval.singular.cashAdvanceRequest',
      redirectPathName: 'apps-transactions-cash-advance-request-list'
    })

    const form = ref({
      code: '',
      date: '',
      purposeDate: '',
      employeeId: '',
      description: '',
      type: '',
      requestAmount: 0,
      approvedAmount: 0,
      approvedDate: formatDatepicker(Date.now()),
      details: []
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    const totalApprovedAmount = ref(0)
    const sumApprovedAmount = () => {
      form.value.approvedAmount = form.value.details.reduce((accumulator, {approvedAmount}) => accumulator + approvedAmount, 0)
      totalApprovedAmount.value = formatCurrency(form.value.approvedAmount)
    }

    return {
      form,
      sumApprovedAmount,
      totalApprovedAmount,
      numeric,
      paramsId,
      formRef,
      get,
      customStore,
      actions,
      changeToEdit
    }
  },
  mounted () {
    if (this.paramsId) {
      this.actions.isPreview = true
      this.getDataPreview()
    }
  },
  methods: {
    async getDataPreview () {
      const data = await this.get({
        url: `transaction/cash-advance/request/${this.paramsId}`
      })

      this.form.code = data.code
      this.form.date = data.date
      this.form.purposeDate = data.purpose_date
      this.form.employeeId = data.employee.pic_name
      this.form.description = data.description
      this.form.type = data.type.name
      this.form.requestAmount = formatCurrency(data.request_amount)

      await Promise.all((data.details || []).map((detail) => {
        this.form.details.push({
          id: detail.id,
          account: `${detail.account.account_number} - ${detail.account.account_name}`,
          description: detail.description,
          requestAmount: detail.request_amount,
          approvedAmount: detail.request_amount,
        })
      }))

      this.sumApprovedAmount()
    },

    handleSubmit () {
      const data = {
        approvedAmount: this.form.approvedAmount,
        isApproved: true,
        approvedDate: this.form.approvedDate,
        details: []
      }

      this.form.details.map((detail) => {
        data.details.push({
          id: detail.id,
          approvedAmount: detail.approvedAmount
        })
      })

      this.customStore({
        $swal: this.$swal,
        data,
        url: `transaction/cash-advance/request/${this.paramsId}/approve`,
        text: {
          swalTitle: `${this.$t('confirm.approveThis')} ${this.$t('apps.transactionsCashAdvanceRequestApproval.singular.cashAdvanceRequest')} ?`,
          swalText: `${this.$t('info.afterApproved')} ${this.$t('info.notAbleRecover')}.`,
          successText: `${this.$t('feedback.approved')} ${this.$t('apps.transactionsCashAdvanceRequestApproval.singular.cashAdvanceRequest')}.`
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
